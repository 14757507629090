<!--
 * @Author       : JiangChao
 * @Date         : 2021-09-30 14:46:57
 * @LastEditors  : JiangChao
 * @LastEditTime : 2024-09-10 16:13:18
 * @Description  : 
-->
<template>
  <div class="container">
    <img src="../assets/404.png" alt="" srcset="" />
  </div>
</template>

<script>
export default {
  name: "404",
};
</script>

<style lang="less" scoped>
.container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  img{
	width: 400px;
	height: 100px;
	margin-top: 200px;
  }
}
</style>
